.main-container {
    margin: 0px !important;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
}

header {
    width: 100%;
    background-color: #E6176F;
    margin: 0px !important;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120px;
}

header h1 {
    color: white;
    font-weight: bold;
    font-size: 1.9em;
    letter-spacing: 0.15em;
}

.textContainer h2 {
    color: #2A3972;
    font-weight: bold;
    text-align: justify;
    font-size: 1.1em;
}

p, li {
    text-align: justify;
    font-size: 1.02em;
    color: #505050;
}

b {
    color: #2A3972;
}

ul{
    padding-right: 25px;
}

li {
    margin-bottom: 10px;  
}

.textContainer {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 20px;
    max-width: 1000px;
    margin: 0px auto;
}